import _owner from "utilise/owner";
import _log from "utilise/log";
var exports = {};

// -------------------------------------------
// Exposes a convenient global instance 
// -------------------------------------------
exports = function singleton(ripple) {
  log("creating");
  if (!owner.ripple) owner.ripple = ripple;
  return ripple;
};

const owner = _owner,
      log = _log("[ri/singleton]");

export default exports;